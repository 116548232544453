import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledSideBySideTitleParagraphComponent = styled.div`
  &.side-by-side-title-paragraph-component {
    .side-by-side-text-and-asset {
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
      margin: auto;

      .side-by-side-content {
        .full-paragraph-component-wrapper {
          min-width: ${({ titleMinWidth })=>titleMinWidth};
        }

        .image-wrapper {
          align-self: ${({ paragraphVerticalAlignment })=>paragraphVerticalAlignment};
        }
      }
    }
  }
`;
